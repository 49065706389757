import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Stepper, Step, StepLabel, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { firestore, auth } from '../services/firebaseConfig';

const FirstLoginWizard: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [companyDetails, setCompanyDetails] = useState({ name: '', address: '' });
  const [clientDetails, setClientDetails] = useState({ name: '', email: '' });
  const [invoiceDetails, setInvoiceDetails] = useState({ number: '', series: '' });
  const navigate = useNavigate();

  const steps = ['Company Details', 'Client Details', 'Invoice Details'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await firestore.collection('users').doc(user.uid).update({
          companyDetails,
          clientDetails,
          invoiceDetails,
          firstLogin: false,
        });
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error saving configuration:', error);
    }
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={companyDetails.name}
              onChange={(e) => setCompanyDetails({ ...companyDetails, name: e.target.value })}
              style={{ marginBottom: '1rem' }}
            />
            <TextField
              label="Company Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={companyDetails.address}
              onChange={(e) => setCompanyDetails({ ...companyDetails, address: e.target.value })}
              style={{ marginBottom: '1rem' }}
            />
          </div>
        );
      case 1:
        return (
          <div>
            <TextField
              label="Client Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={clientDetails.name}
              onChange={(e) => setClientDetails({ ...clientDetails, name: e.target.value })}
              style={{ marginBottom: '1rem' }}
            />
            <TextField
              label="Client Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={clientDetails.email}
              onChange={(e) => setClientDetails({ ...clientDetails, email: e.target.value })}
              style={{ marginBottom: '1rem' }}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <TextField
              label="Invoice Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={invoiceDetails.number}
              onChange={(e) => setInvoiceDetails({ ...invoiceDetails, number: e.target.value })}
              style={{ marginBottom: '1rem' }}
            />
            <TextField
              label="Invoice Series"
              variant="outlined"
              fullWidth
              margin="normal"
              value={invoiceDetails.series}
              onChange={(e) => setInvoiceDetails({ ...invoiceDetails, series: e.target.value })}
              style={{ marginBottom: '1rem' }}
            />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper style={{ padding: '2rem', marginTop: '2rem', backgroundColor: '#F5F5DC', border: '1px solid #556B2F' }}>
        <Typography variant="h4" align="center" style={{ color: '#556B2F', marginBottom: '1rem' }}>
          First Login Configuration
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography variant="h6" align="center">
                All steps completed
              </Typography>
              <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '1rem', backgroundColor: '#556B2F', color: '#FFFFFF' }}>
                Finish
              </Button>
            </div>
          ) : (
            <div>
              {getStepContent(activeStep)}
              <div style={{ marginTop: '2rem' }}>
                <Button disabled={activeStep === 0} onClick={handleBack} style={{ marginRight: '1rem', backgroundColor: '#8FBC8F', color: '#FFFFFF' }}>
                  Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext} style={{ backgroundColor: '#556B2F', color: '#FFFFFF' }}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Paper>
    </Container>
  );
};

export default FirstLoginWizard;
