import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import { collection, getDocs, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { firestore, auth, functions } from '../../services/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

interface RegistrationRequest {
  id: string;
  tvaCode: string;
  email: string;
  pictureUrl: string;
  timestamp: any;
}

const AdminDashboard: React.FC = () => {
  const { isAdmin } = useContext(AuthContext);
  const [requests, setRequests] = useState<RegistrationRequest[]>([]);

  useEffect(() => {
    if (isAdmin) {
      fetchRegistrationRequests();
    }
  }, [isAdmin]);

  const fetchRegistrationRequests = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'registrationRequests'));
      const reqs: RegistrationRequest[] = [];
      querySnapshot.forEach((docSnap) => {
        const data = docSnap.data() as RegistrationRequest;
        reqs.push({
          ...data,
          id: docSnap.id,
        });
      });
      setRequests(reqs);
    } catch (error) {
      console.error('Error fetching registration requests:', error);
    }
  };

  const handleAccept = async (request: RegistrationRequest) => {
    const randomPassword = Math.random().toString(36).slice(-8);

    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        request.email,
        randomPassword
      );

      // Save user details in Firestore
      await setDoc(doc(firestore, 'users', userCredential.user.uid), {
        uid: userCredential.user.uid,
        email: request.email,
        tvaCode: request.tvaCode,
        pictureUrl: request.pictureUrl,
        createdAt: new Date(),
      });

      // Send confirmation email with the password
      const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');
      await sendWelcomeEmail({
        email: request.email,
        password: randomPassword,
      });

      // Remove the registration request
      await deleteDoc(doc(firestore, 'registrationRequests', request.id));

      // Refresh the list
      fetchRegistrationRequests();

      alert(`User ${request.email} has been accepted.`);
    } catch (error) {
      console.error('Error accepting registration request:', error);
      alert('An error occurred while accepting the registration request.');
    }
  };

  const handleReject = async (request: RegistrationRequest) => {
    try {
      // Optionally send a rejection email here

      // Remove the registration request
      await deleteDoc(doc(firestore, 'registrationRequests', request.id));

      // Refresh the list
      fetchRegistrationRequests();

      alert(`User ${request.email} has been rejected.`);
    } catch (error) {
      console.error('Error rejecting registration request:', error);
      alert('An error occurred while rejecting the registration request.');
    }
  };

  if (!isAdmin) {
    return (
      <Container>
        <Typography variant="h6" align="center" style={{ marginTop: '2rem' }}>
          Access Denied. You are not authorized to view this page.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" align="center" style={{ marginTop: '2rem' }}>
        Admin Dashboard
      </Typography>
      <Paper style={{ marginTop: '2rem', padding: '1rem' }}>
        <Typography variant="h6">Pending Registration Requests</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>TVA Code</TableCell>
              <TableCell>Picture</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow key={request.id}>
                <TableCell>{request.email}</TableCell>
                <TableCell>{request.tvaCode}</TableCell>
                <TableCell>
                  {request.pictureUrl ? (
                    <img
                      src={request.pictureUrl}
                      alt="User"
                      style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                  ) : (
                    'No Picture'
                  )}
                </TableCell>
                <TableCell>
                  {request.timestamp
                    ? new Date(request.timestamp.seconds * 1000).toLocaleString()
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAccept(request)}
                    style={{ marginRight: '0.5rem' }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleReject(request)}
                  >
                    Reject
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {requests.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No pending registration requests.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default AdminDashboard;
