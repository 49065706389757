import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { List, ListItemButton, ListItemText, ListItemIcon, IconButton, Drawer, Avatar, Typography } from '@mui/material';
import { Settings, Dashboard, ExitToApp, Menu } from '@mui/icons-material';

const Sidebar: React.FC = () => {
  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const getUserInitial = (email: string) => {
    return email.charAt(0).toUpperCase();
  };

  return (
    <Drawer variant="persistent" anchor="left" open={isOpen} style={{ backgroundColor: '#556B2F', padding: '20px' }}>
      <div>
        <IconButton onClick={toggleSidebar}>
          <Menu />
        </IconButton>
      </div>
      <div>
        <Avatar>{currentUser?.photoURL ? <img src={currentUser.photoURL} alt="Profile" /> : getUserInitial(currentUser?.email || '')}</Avatar>
        <Typography variant="h6" style={{ color: '#8FBC8F', margin: '10px 0' }}>{currentUser?.email}</Typography>
      </div>
      <List>
        <ListItemButton component={Link} to="/settings">
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
        <ListItemButton component={Link} to="/dashboard">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton component={Link} to="/logout">
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default Sidebar;
