import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Avatar,
    Grid,
    Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { firestore, storage, firebase } from '../../services/firebaseConfig';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import axios, { AxiosResponse } from 'axios';

const useStyles = makeStyles({
    paper: {
        marginTop: '2rem',
        padding: '2rem',
    },
    avatar: {
        width: '7rem',
        height: '7rem',
        margin: '0 auto',
        backgroundColor: '#f0f0f0',
    },
    uploadButton: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    form: {
        marginTop: '1rem',
    },
    submitButton: {
        marginTop: '2rem',
    },
});

const Register: React.FC = () => {
    const classes = useStyles();

    const [tvaCode, setTvaCode] = useState('');
    const [email, setEmail] = useState('');
    const [picture, setPicture] = useState<File | null>(null);
    const [picturePreview, setPicturePreview] = useState<string | null>(null);
    const [errors, setErrors] = useState<{ tvaCode?: string; email?: string }>({});

    const validate = async () => {
        let tempErrors: { tvaCode?: string; email?: string } = {};
        if (!tvaCode.trim()) tempErrors.tvaCode = 'TVA Code is required.';
        else if (!/^RO\d{8}$/.test(tvaCode) && !/^\d{8}$/.test(tvaCode)) tempErrors.tvaCode = 'TVA Code is not valid.';
        if (!email) {
            tempErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            tempErrors.email = 'Email is not valid.';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };


    const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setPicture(e.target.files[0]);
            setPicturePreview(URL.createObjectURL(e.target.files[0]));
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!await validate()) return;

        try {
            // Upload picture to Firebase Storage
            let pictureUrl = '';
            if (picture) {
                const storageRef = storage.ref();
                const pictureRef = storageRef.child(`userPictures/${picture.name}`);
                await pictureRef.put(picture);
                pictureUrl = await pictureRef.getDownloadURL();
            }

            // Create registration request in Firestore if there is no registration already for tvaCode
            const existingRequests = await firestore
                .collection('registrationRequests')
                .where('tvaCode', '==', tvaCode)
                .get();
            if (!existingRequests.empty) {
                alert('Registration request already exists for this TVA code.');
                return;
            }

            await firestore.collection('registrationRequests').add({
                tvaCode,
                email,
                pictureUrl,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });

            // Reset form and display success message
            setTvaCode('');
            setEmail('');
            setPicture(null);
            setPicturePreview(null);
            alert('Registration request submitted successfully!');
        } catch (error) {
            console.error('Error submitting registration request:', error);
            alert('An error occurred while submitting your request.');
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper className={classes.paper} elevation={3}>
                <Typography variant="h4" align="center">
                    Register
                </Typography>
                <Grid container direction="column" alignItems="center">
                    <Avatar className={classes.avatar} src={picturePreview ?? ''}>
                        {!picturePreview && <AddAPhotoIcon fontSize="large" />}
                    </Avatar>
                    <div className={classes.uploadButton}>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-picture"
                            type="file"
                            onChange={handlePictureChange}
                        />
                        <label htmlFor="upload-picture">
                            <Button variant="contained" color="primary" component="span">
                                Upload Picture
                            </Button>
                        </label>
                    </div>
                </Grid>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        label="TVA Code"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        value={tvaCode}
                        onChange={(e) => setTvaCode(e.target.value)}
                        error={Boolean(errors.tvaCode)}
                        helperText={errors.tvaCode}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.submitButton}
                    >
                        Submit Registration
                    </Button>
                </form>
            </Paper>
        </Container>
    );
};

export default Register;
