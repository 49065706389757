import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth, firestore } from '../services/firebaseConfig';

interface AuthContextType {
  currentUser: User | null;
  isAdmin: boolean;
  isFirstLogin: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isAdmin: false,
  isFirstLogin: false,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);

      if (user) {
        // Get the ID token result
        const idTokenResult = await user.getIdTokenResult(true);
        // Set isAdmin based on custom claims
        setIsAdmin(!!idTokenResult.claims.admin);

        // Check if it's the user's first login
        const userDoc = await firestore.collection('users').doc(user.uid).get();
        const userData = userDoc.data();
        setIsFirstLogin(userData?.firstLogin || false);
      } else {
        setIsAdmin(false);
        setIsFirstLogin(false);
      }

      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <AuthContext.Provider value={{ currentUser, isAdmin, isFirstLogin }}> {!loading && children}</AuthContext.Provider>;
};
