import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import Dashboard from './components/Dashboard/ClientManagement';
import PrivateRoute from './components/PrivateRoute';
import AdminDashboard from './components/Admin/RegistrationRequests';
import { AuthContext } from './contexts/AuthContext';
import Sidebar from './components/Shared/Sidebar';
import FirstLoginWizard from './components/FirstLoginWizard';

const App: React.FC = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Navigate to={currentUser ? "/dashboard" : "/login"} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute adminOnly>
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/first-login"
              element={
                <PrivateRoute>
                  <FirstLoginWizard />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
