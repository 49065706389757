import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

const firebaseConfig = {
  apiKey: "AIzaSyADPQZMpppkAg4AhU6Y7i3vVPahIkVIA3o",
  authDomain: "decatofactura.firebaseapp.com",
  projectId: "decatofactura",
  storageBucket: "decatofactura.firebasestorage.app",
  messagingSenderId: "795956106612",
  appId: "1:795956106612:web:c3d9cff7130e5f65f50821"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();

const updateFirstLoginStatus = async (userId: string, status: boolean) => {
  try {
    await firestore.collection('users').doc(userId).update({
      firstLogin: status,
    });
  } catch (error) {
    console.error('Error updating first login status:', error);
  }
};

export { firebase, auth, firestore, storage, functions, updateFirstLoginStatus };
