import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Paper } from '@mui/material';
import { firestore, auth } from '../../services/firebaseConfig';

const ClientManagement: React.FC = () => {
  const [clientCompanyDetails, setClientCompanyDetails] = useState({ name: '', address: '', email: '' });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const user = auth.currentUser;
      if (user) {
        await firestore.collection('clients').add({
          ...clientCompanyDetails,
          userId: user.uid,
        });
        setClientCompanyDetails({ name: '', address: '', email: '' });
        alert('Client company details saved successfully.');
      }
    } catch (error) {
      console.error('Error saving client company details:', error);
      alert('An error occurred while saving client company details.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper style={{ padding: '2rem', marginTop: '2rem', backgroundColor: '#F5F5DC', border: '1px solid #556B2F' }}>
        <Typography variant="h4" align="center" style={{ color: '#556B2F', marginBottom: '1rem' }}>
          Client Management
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Client Company Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientCompanyDetails.name}
            onChange={(e) => setClientCompanyDetails({ ...clientCompanyDetails, name: e.target.value })}
            style={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Client Company Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientCompanyDetails.address}
            onChange={(e) => setClientCompanyDetails({ ...clientCompanyDetails, address: e.target.value })}
            style={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Client Company Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientCompanyDetails.email}
            onChange={(e) => setClientCompanyDetails({ ...clientCompanyDetails, email: e.target.value })}
            style={{ marginBottom: '1rem' }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '1rem', backgroundColor: '#556B2F', color: '#FFFFFF' }}>
            Save Client Company Details
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ClientManagement;
